// TODO: remove unused.
import loadable from '@loadable/component';

import Layout from 'layouts/Admin';

export const USER_ADMIN_ROUTE = '/users/:id/assets';
export const CONFIRMATION_ROUTE = '/confirmation';
export const EDIT_ORGANIZATION_ROUTE = '/edit-organization/:id';
export const CREATE_ORGANIZATION_ROUTE = '/create-organization';
export const INBOX_ROUTE = '/users/:user/documents';
export const INTEGRATIONS_ROUTE = '/integrations';
export const NEW_DOCUMENT_ROUTE = '/';
export const DOCUMENT_ROUTE =
  '/teams/:teamId/folders/:folderId/document/:documentId';
export const ORGANIZATIONS_ROUTE = '/organizations';
export const PROFILE_ROUTE = '/profile';
export const MARKETING = '/marketing';
export const MARKETING_REWARDS = '/marketing/rewards';
export const MARKETING_REWARDS_UPDATE = '/marketing/rewards/:uuid';
export const REWARDS_ROUTE = '/rewards';
export const REWARD_DETAILS_ROUTE = '/rewards/:uuid';
export const FORMS_ROUTE = '/forms';
export const FORMS_ROUTE_NEW = '/forms/new';
export const FORMS_ROUTE_EDIT = '/forms/edit/:id';
export const FORMS_ROUTE_USE = ['/forms/use/:id', '/forms/use'];
export const FORMS_ROUTE_SIGN = '/forms/sign/:id';
export const FORMS_REDIRECT = '/teams/:teamId/forms/:formId';
export const CONTACTS_ROUTE = '/teams/:team/contacts';
export const TEAM_CREATE_ROUTE = '/teams/create';
export const TEAM_LISTING_ROUTE = '/teams';
export const TEAM_UPDATE_ROUTE = '/teams/:team';
export const TEAM_FOLDERS_ROUTE = '/teams/:team/folders';
export const TEAM_FOLDER_DOCUMENTS_ROUTE =
  '/teams/:team/folders/:folder/documents';
// LEGACY ROUTES
export const LEGACY_INVITATION_ROUTE = '/sign_invitation';
export const LEGACY_DOCUMENT_ROUTE = '/document/:id';
export const LEGACY_NEW_DOCUMENT_ROUTE = '/legacy/create-document';
export const PKCS7 = '/pkcs7/test';

const UserAdminPage = loadable(() => {
  return import('@pages/UserPages/AdminPage');
});
const EditOrganizationPage = loadable(() => {
  return import('@pages/OrganizationsPage/EditOrganizationPage');
});
const CreateOrganizationPage = loadable(() => {
  return import('@pages/OrganizationsPage/CreateOrganizationPage');
});
const IntegrationsPage = loadable(() => {
  return import('@pages/IntegrationsPage');
});
const CreateDocument = loadable(() => {
  return import('@pages/DocumentPage/CreateDocument');
});
const SignDocument = loadable(() => {
  return import('@pages/DocumentPage/SignDocument');
});
const OrganizationsPage = loadable(() => {
  return import('@pages/OrganizationsPage/OrganizationsPage');
});
const ProfilePage = loadable(() => {
  return import('@pages/UserPages/ProfilePage');
});
const MarketingCreatePage = loadable(() => {
  return import('@pages/MarketingPages/CreatePage');
});
const MarketingRewardsPage = loadable(() => {
  return import('@pages/MarketingPages/RewardsPages/ListingPage');
});
const MarketingRewardsUpdatePage = loadable(() => {
  return import('@pages/MarketingPages/RewardsPages/UpdatePage');
});
const RewardsPage = loadable(() => {
  return import('@pages/RewardsPages/ListingPage');
});
const RewardDetailsPage = loadable(() => {
  return import('@pages/RewardsPages/DetailsPage');
});
const FormTemplatesPage = loadable(() => {
  return import('@pages/FormTemplatesPage/ListingPage');
});
const NewFormPage = loadable(() => {
  return import('@pages/FormTemplatesPage/CreateFormPage');
});
const EditFormPage = loadable(() => {
  return import('@pages/FormTemplatesPage/CreateFormPage');
});
const UseFormPage = loadable(() => {
  return import('@pages/FormTemplatesPage/UseFormPage');
});
const SignFormPage = loadable(() => {
  return import('@pages/FormTemplatesPage/SignFormPage');
});
const RedirectFormPage = loadable(() => {
  return import('@pages/FormTemplatesPage/FormRedirectPage');
});
const ContactsPage = loadable(() => {
  return import('@pages/ContactsPage');
});
const TeamCreatePage = loadable(() => {
  return import('@pages/TeamsPages/pages/CreatePage');
});
const TeamListingPage = loadable(() => {
  return import('@pages/TeamsPages/pages/ListingPage');
});
const TeamUpdatePage = loadable(() => {
  return import('@pages/TeamsPages/pages/UpdatePage');
});
const TeamFoldersPage = loadable(() => {
  return import('@pages/DirectoriesPage');
});
const TeamFolderDocumentsPage = loadable(() => {
  return import('@pages/DocumentsPages/pages/ListingPage');
});
const InboxPage = loadable(() => {
  return import('@pages/InboxPage');
});
// LEGACY PAGES
const LegacySignDocument = loadable(() => {
  return import('@pages/LegacyPages/DocumentPage/SignDocument');
});
// const LegacyCreateDocument = loadable(() => {
//   return import('@pages/LegacyPages/DocumentPage/CreateDocument');
// });

const PKCS7Component = loadable(() => {
  return import('@pages/PKCS7Component');
});

const Test = loadable(() => {
  return import('./PdfComponentExample');
});

const AboutPage = loadable(() => {
  return import('@pages/AboutPage');
});

interface RouteOptions {
  key: string;
  path: string | Array<string>;
  isPrivate: boolean;
  component: any;
  layout?: any;
  policy?: string;
}

export const ROUTES: Array<RouteOptions> = [
  {
    key: 'user/admin',
    path: USER_ADMIN_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: UserAdminPage,
  },
  {
    key: 'inbox',
    path: INBOX_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: InboxPage,
  },
  {
    key: 'marketing',
    path: MARKETING,
    isPrivate: true,
    layout: Layout,
    component: MarketingCreatePage,
  },
  {
    key: 'marketing-rewards',
    path: MARKETING_REWARDS,
    isPrivate: true,
    layout: Layout,
    component: MarketingRewardsPage,
  },
  {
    key: 'marketing-rewards-update',
    path: MARKETING_REWARDS_UPDATE,
    isPrivate: true,
    layout: Layout,
    component: MarketingRewardsUpdatePage,
  },
  {
    key: 'rewards',
    path: REWARDS_ROUTE,
    isPrivate: false,
    layout: Layout,
    component: RewardsPage,
  },
  {
    key: 'reward-details',
    path: REWARD_DETAILS_ROUTE,
    isPrivate: false,
    layout: Layout,
    component: RewardDetailsPage,
  },
  {
    key: 'edit-organization',
    path: EDIT_ORGANIZATION_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: EditOrganizationPage,
  },
  {
    key: 'create-organization',
    path: CREATE_ORGANIZATION_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: CreateOrganizationPage,
  },
  {
    key: 'folders',
    path: TEAM_FOLDERS_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: TeamFoldersPage,
  },
  {
    key: 'integrations',
    path: INTEGRATIONS_ROUTE,
    isPrivate: false,
    layout: Layout,
    component: IntegrationsPage,
  },
  {
    key: 'new-document',
    path: NEW_DOCUMENT_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: CreateDocument,
  },
  {
    key: 'document',
    path: DOCUMENT_ROUTE,
    isPrivate: false,
    layout: Layout,
    component: SignDocument,
  },
  {
    key: 'organizations',
    path: ORGANIZATIONS_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: OrganizationsPage,
  },
  {
    key: 'profle',
    path: PROFILE_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: ProfilePage,
  },
  {
    key: 'forms',
    path: FORMS_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: FormTemplatesPage,
  },
  {
    key: 'forms-new',
    path: FORMS_ROUTE_NEW,
    isPrivate: true,
    layout: Layout,
    component: NewFormPage,
  },
  {
    key: 'forms-edit',
    path: FORMS_ROUTE_EDIT,
    isPrivate: true,
    layout: Layout,
    component: EditFormPage,
  },
  {
    key: 'forms-use',
    path: FORMS_ROUTE_USE,
    isPrivate: true,
    layout: Layout,
    component: UseFormPage,
  },
  {
    key: 'forms-sign',
    path: FORMS_ROUTE_SIGN,
    isPrivate: false,
    layout: Layout,
    component: SignFormPage,
  },
  {
    key: 'forms-redirect',
    path: FORMS_REDIRECT,
    isPrivate: false,
    layout: Layout,
    component: RedirectFormPage,
  },
  {
    key: 'contacts',
    path: CONTACTS_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: ContactsPage,
  },
  {
    key: 'team-create',
    path: TEAM_CREATE_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: TeamCreatePage,
  },
  {
    key: 'team-listing',
    path: TEAM_LISTING_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: TeamListingPage,
  },
  {
    key: 'team-update',
    path: TEAM_UPDATE_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: TeamUpdatePage,
  },
  {
    key: 'team-folder-documents',
    path: TEAM_FOLDER_DOCUMENTS_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: TeamFolderDocumentsPage,
  },
  {
    key: 'PKCS7',
    path: PKCS7,
    isPrivate: false,
    layout: Layout,
    component: PKCS7Component,
  },
  // LEGACY SEGURIDATA ROUTES.
  {
    key: 'LEGACY-document',
    path: LEGACY_DOCUMENT_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: LegacySignDocument,
  },
  {
    key: 'LEGACY-invitation-sign',
    path: LEGACY_INVITATION_ROUTE,
    isPrivate: false,
    layout: Layout,
    component: LegacySignDocument,
  },
  // {
  //   key: 'LEGACY-new-document',
  //   path: LEGACY_NEW_DOCUMENT_ROUTE,
  //   isPrivate: true,
  //   layout: Layout,
  //   component: LegacyCreateDocument,
  // },
  {
    key: 'Pdf-example',
    path: '/example',
    isPrivate: false,
    layout: Layout,
    component: Test,
  },
  {
    key: 'about-page',
    path: '/about',
    isPrivate: true,
    layout: Layout,
    component: AboutPage,
  },
];
