/**
 * CINCEL API SDK. Allows you to create instances of a client
 * with access to the Cincel API.
 * @namespace Cincel
 * @see CINCELClient
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import { getHeaders } from './headers';
import _ from 'lodash';
import qs from 'query-string';
import fp from 'lodash/fp';
export var tokenName = 'tokenCincel';
/**
 * Cincel API client.
 * @class
 * @name CINCELClient
 * @memberof Cincel
 * @param {CINCELClientConfig} config - Client configuration parameters
 * @prop {string} config.baseURL - The base url of the API environment.
 * @prop {string} config.token - The token got from login service
 * @example
 * import { CINCELClient } from '@cincel/client';
 * const client = new CINCELClient({
 *    baseURL: 'http://54.82.180.29:8000',
 *    token: '1234567890'
 * });
 */
var CINCELClient = /** @class */ (function () {
    function CINCELClient(_a) {
        var baseURL = _a.baseURL, _b = _a.defaultPageSize, defaultPageSize = _b === void 0 ? 10 : _b, _c = _a.token, token = _c === void 0 ? localStorage.getItem(tokenName) : _c;
        this.defaultPageSize = defaultPageSize;
        var headers = __assign({}, getHeaders());
        if (token) {
            headers['authorization'] = "Bearer ".concat(token);
        }
        this.http = axios.create({
            baseURL: baseURL,
            headers: headers,
        });
    }
    CINCELClient.prototype.getAxiosInstance = function () {
        return this.http;
    };
    /**
     * Set the Authentication header with a valid JWT token.
     * @param token {null | string} - authentication token.
     */
    CINCELClient.prototype.setAuthorizationHeader = function (token) {
        if (token === void 0) { token = null; }
        var path = ['defaults', 'headers', 'common', 'Authorization'];
        if (token)
            _.set(this.http, path, "Bearer ".concat(token));
    };
    /**
     * Get v3 token.
     * @param {{ username: string, password: string }} p - auth params.
     */
    CINCELClient.prototype.getV3Token = function (p) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/tokens/jwt";
                        return [4 /*yield*/, this.http.get(url, !_.isNil(p) ? { auth: p } : {})];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Get user session information from login credentials.
     *
     * @param {IBaseAuthentication} body - The body of the request
     */
    CINCELClient.prototype.login = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post('/v1/login/', body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Get user session information from login credentials.
     *
     * @param {IBaseAuthentication} body - The body of the request
     */
    CINCELClient.prototype.renewLogin = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (body) {
                            this.http.interceptors.request.use(function (config) {
                                config.headers['authorization'] = body;
                                return config;
                            });
                        }
                        return [4 /*yield*/, this.http.post('/v1/login/renew')];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Register
     *
     * @param {SignUpBody} body - The body of the request
     */
    CINCELClient.prototype.signup = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post('/users', body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Confirmation
     *
     * @param {SignUpBody} body - The body of the request
     */
    CINCELClient.prototype.confirmEmail = function (email, body) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post("/users/".concat(email.toLowerCase(), "/confirmation-code"), body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Request new code
     *
     * @param {SignUpBody} body - The body of the request
     */
    CINCELClient.prototype.requestNewCode = function (email) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get("/users/".concat(email.toLowerCase(), "/verification-code"))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     *
     * Reset password
     *
     * @param
     * @returns
     */
    CINCELClient.prototype.resetPassword = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var email, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        email = payload.email;
                        return [4 /*yield*/, this.http.get("/users/".concat(email, "/recovery-token"))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     *
     * Recover password
     *
     * @param
     * @returns
     */
    CINCELClient.prototype.recoverPassword = function (token, body) {
        return __awaiter(this, void 0, void 0, function () {
            var email, password, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        email = body.email, password = body.password;
                        return [4 /*yield*/, this.http.patch("/users/".concat(email, "/recovery-token"), { password: password }, { headers: { Authorization: "Bearer ".concat(token) } })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Send Reminder
     *
     * @param body
     * @param id
     * @returns
     */
    CINCELClient.prototype.sendReminder = function (body, id) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post("/v1/invitations/".concat(id, "/reminder"), body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Fetch folders
     *
     */
    CINCELClient.prototype.getDocuments = function (userId, organization, folder, search, params) {
        if (search === void 0) { search = ''; }
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/v1/users/".concat(userId, "/documents");
                        return [4 /*yield*/, this.http.get(path, {
                                params: __assign({ folder_id: folder > 0 ? folder : undefined, organization_id: organization > 0 ? organization : undefined, q: search }, params),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Fetch folders
     *
     */
    CINCELClient.prototype.folders = function (organization) {
        return __awaiter(this, void 0, void 0, function () {
            var path, finalPath, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = '/v1/folders';
                        finalPath = typeof organization !== 'undefined' && organization > 0
                            ? "".concat(path, "?organization_id=").concat(organization)
                            : path;
                        return [4 /*yield*/, this.http.get(finalPath)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Create folder
     *
     */
    CINCELClient.prototype.createFolder = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = '/v1/folders';
                        return [4 /*yield*/, this.http.post(path, body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Update folder
     *
     */
    CINCELClient.prototype.updateFolder = function (id, body) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/v1/folders/".concat(id);
                        return [4 /*yield*/, this.http.put(path, body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Delete folder
     *
     */
    CINCELClient.prototype.deleteFolder = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/v1/folders/".concat(id);
                        return [4 /*yield*/, this.http.delete(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Account limts
     *
     */
    CINCELClient.prototype.accountLimits = function (organizationId) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = organizationId > 0
                            ? "/v1/organizations/".concat(organizationId, "/account_limits")
                            : '/v1/account_limits';
                        return [4 /*yield*/, this.http.get(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Account subscription
     *
     */
    CINCELClient.prototype.accountSubscription = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get('/v1/users/subscriptions')];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * DEPRECATED
     * Create document
     *
     */
    CINCELClient.prototype.createDocument = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post('/v1/documents', body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Sign a document using API V3
     */
    CINCELClient.prototype.signDocumentV3 = function (team, folder, document, invite, payload, headers) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document, "/invites/").concat(invite, "/signature.png");
                        return [4 /*yield*/, this.http.post(path, payload, {
                                headers: __assign({ 'Content-Type': 'image/png' }, headers),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Sign a document using API V3
     */
    CINCELClient.prototype.fetchSignedDocument = function (team, folder, document) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document, "/signed-document.pdf");
                        return [4 /*yield*/, this.http.get(path, {
                                responseType: 'blob',
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Get token from invitation to sign document
     */
    CINCELClient.prototype.getInviteToken = function (team, folder, document, invite) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document, "/invites/").concat(invite, "/token");
                        return [4 /*yield*/, this.http.get(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Gets a document by id using API V3
     */
    CINCELClient.prototype.getDocumentV3 = function (team, folder, document) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document);
                        return [4 /*yield*/, this.http.get(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Create a document with the API V3
     */
    CINCELClient.prototype.createDocumentV3 = function (team, folder, document, _a) {
        var metadata = _a.metadata, observers = _a.observers, signers = _a.signers;
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        path = "/teams/".concat(team, "/folders/").concat(folder, "/documents");
                        metadata = fp.isEmpty(metadata.description)
                            ? { name: metadata.name }
                            : metadata;
                        return [4 /*yield*/, this.http.post(path, document, {
                                headers: __assign({ 'Content-Type': 'application/pdf', metadata: JSON.stringify(metadata), signers: JSON.stringify(signers) }, (observers && observers.length > 0
                                    ? {
                                        observers: JSON.stringify(observers),
                                    }
                                    : {})),
                            })];
                    case 1:
                        response = _b.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Deletes a document by id using API V3
     */
    CINCELClient.prototype.deleteDocumentV3 = function (team, folder, document) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document);
                        return [4 /*yield*/, this.http.delete(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Gets a document invitations using API V3
     */
    CINCELClient.prototype.getDocumentInvitationsV3 = function (team, folder, document, params) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document, "/invites");
                        return [4 /*yield*/, this.http.get(path, { params: params })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Post a document invitation.
     */
    CINCELClient.prototype.postTeamFolderDocumentInvite = function (team, folder, document, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document, "/invites");
                        return [4 /*yield*/, this.http.post(path, payload)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Gets a document invitations using API V3
     */
    CINCELClient.prototype.resendInvitationV3 = function (team, folder, document, invite) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document, "/invites/").concat(invite, "/notification");
                        return [4 /*yield*/, this.http.get(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Get invitation by ID using V3
     */
    CINCELClient.prototype.getDocumentInvitationByIdV3 = function (team, folder, document, invite) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document, "/invites/").concat(invite);
                        return [4 /*yield*/, this.http.get(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Edit invitation by ID using V3
     */
    CINCELClient.prototype.patchDocumentInvitationByIdV3 = function (team, folder, document, invite, data) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document, "/invites/").concat(invite);
                        return [4 /*yield*/, this.http.patch(path, data)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Delete invitation by ID using V3
     */
    CINCELClient.prototype.deletehDocumentInvitationByIdV3 = function (team, folder, document, invite) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document, "/invites/").concat(invite);
                        return [4 /*yield*/, this.http.delete(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Create invitations
     */
    CINCELClient.prototype.createInvitations = function (body, documentId) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post("/v1/documents/".concat(documentId, "/invitations"), body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Update invitations
     */
    CINCELClient.prototype.updateInvitations = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.put("/v1/invites/update", body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Delete invitation
     */
    CINCELClient.prototype.deleteInvitation = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.delete("/v1/invites/delete?id=".concat(id))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Get document
     * DEPRECATED
     */
    CINCELClient.prototype.getDocumentById = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get("/v1/documents/".concat(id))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     *  Delete document
     */
    CINCELClient.prototype.deleteDocumentById = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.delete("/v1/documents/".concat(id))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     *  Update document
     */
    CINCELClient.prototype.updateDocumentById = function (id, body) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.put("/v1/documents/".concat(id), body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Get document
     */
    CINCELClient.prototype.getDocumentByIdWithToken = function (id, params) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get("/v1/documents/".concat(id, "/show_with_token"), { params: params })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Post comment
     *
     * @param id
     * @param body
     * @returns
     */
    CINCELClient.prototype.postComment = function (id, body) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post("/v1/files/".concat(id, "/comments"), body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Update Document
     *
     * @param id
     * @param body
     * @returns
     */
    CINCELClient.prototype.updateDocument = function (id, body, token) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/v1/documents/".concat(id, "/files").concat(token ? '/with_token' : '');
                        return [4 /*yield*/, this.http.post(path, body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Update Document File
     *
     * @param id
     * @param body
     * @returns
     */
    CINCELClient.prototype.updateDocumentFile = function (documentId, fileId, body, token) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/v1/documents/".concat(documentId, "/files/").concat(fileId).concat(token ? '/with_token' : '');
                        return [4 /*yield*/, this.http.put(path, body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Get RedirectUrl from Kyc
     * @param id
     * @param email
     * @returns
     */
    CINCELClient.prototype.getKycUrl = function (id, email) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get("/kyc/".concat(id, "/").concat(email))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * KYC Validation
     * @param id
     * @param scanReference
     * @returns
     */
    CINCELClient.prototype.getKtcValidation = function (id, scanReference) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get("/kyc/data/".concat(id, "/").concat(scanReference))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Signature VOBO
  
     * @param body
     * @returns
     */
    CINCELClient.prototype.signVobo = function (body, token) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/v1/sign/vobo/autograph";
                        if (typeof token !== 'undefined') {
                            path += '/with_token';
                        }
                        return [4 /*yield*/, this.http.post(path, body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Hash to sign
     * @param param0
     * @returns
     */
    CINCELClient.prototype.hashToSign = function (body, token) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/v1/sign/autograph/hash_to_sign";
                        if (typeof token !== 'undefined') {
                            path += '/with_token';
                        }
                        return [4 /*yield*/, this.http.post(path, body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Hash to sign advanced
     * @param param0
     * @returns
     */
    CINCELClient.prototype.hashToSignAdvanced = function (body, token) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/v1/sign/advanced/hash_to_sign";
                        if (typeof token !== 'undefined') {
                            path += '/with_token';
                        }
                        return [4 /*yield*/, this.http.post(path, body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Sign autograph with certificte
     * @param param0
     * @returns
     */
    CINCELClient.prototype.signAutographCertificate = function (body, token) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/v1/sign/autograph";
                        if (typeof token !== 'undefined') {
                            path += '/with_token';
                        }
                        return [4 /*yield*/, this.http.post(path, body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Sign advanced
     * @param param0
     * @returns
     */
    CINCELClient.prototype.signAdvanced = function (body, token) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/v1/sign/advanced";
                        if (typeof token !== 'undefined') {
                            path += '/with_token';
                        }
                        return [4 /*yield*/, this.http.post(path, body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Edit profile
     *
     * @param {EditProfileBody} body - The body of the request
     */
    CINCELClient.prototype.editProfile = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.put('/v1/users', body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Create organization
     */
    CINCELClient.prototype.createOrganization = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post("/v1/organizations", body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Update organization
     */
    CINCELClient.prototype.editOrganization = function (id, body) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.put("/v1/organizations/".concat(id), body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Ger organization users
     */
    CINCELClient.prototype.getOrganizationUsers = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get("/v1/organizations/".concat(id, "/users"))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Send invitation to organization
     */
    CINCELClient.prototype.sentInvitationToOrganization = function (id, body) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post("/v1/organizations/".concat(id, "/organization_invitations"), body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Add permission to organization
     */
    CINCELClient.prototype.addPermissionsToInvitation = function (id, body) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post("/v1/organizations/".concat(id, "/organization_permissions/batch"), body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Get organization invitations
     */
    CINCELClient.prototype.getOrganizationInvitations = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get("/v1/organizations/".concat(id, "/organization_invitations"))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Delete invitation from organization
     */
    CINCELClient.prototype.deleteOrganizationInvitation = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.delete("/v1/organization_invitations/".concat(id))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Delete user from organization
     */
    CINCELClient.prototype.deleteOrganizationUser = function (id, userId) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.delete("/v1/organizations/".concat(id, "/users/").concat(userId))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Get invitation by user
     */
    CINCELClient.prototype.getUserInvitation = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get("/v1/users/organization_invitations")];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Accept invitation
     */
    CINCELClient.prototype.acceptInvitation = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.put("/v1/organization_invitations/".concat(id, "/accept"), {})];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Decline invitation
     */
    CINCELClient.prototype.declineInvitation = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.put("/v1/organization_invitations/".concat(id, "/reject"), {})];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Borra las invitaciones de una organizacion que todavia no han sido aceptadas o rechazadas
     */
    CINCELClient.prototype.deleteInvitations = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.put("/v1/organization_invitations/".concat(id))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Get KYC image
     */
    CINCELClient.prototype.getKycImage = function (invitationId, docName) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/kyc/data/".concat(invitationId, "/").concat(docName, ".jpeg");
                        return [4 /*yield*/, this.http.get(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Fetch the documents that the user has been invited to sign.
     */
    CINCELClient.prototype.getInvitedDocuments = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = '/v1/documents/invited';
                        return [4 /*yield*/, this.http.get(path, { params: params })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Fetch global assets.
     * @param {string} asset - name of the asset to request.
     * @param {object} config - request configuration.
     */
    CINCELClient.prototype.getGlobalAssets = function (asset, config) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/assets/".concat(asset);
                        return [4 /*yield*/, this.http.get(path, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Fetch user assets.
     * @param {stirng} id - owner of the organization or document ID.
     * @param {string} asset - name of the asset to request.
     * @param {object} config - request configuration.
     */
    CINCELClient.prototype.getAssets = function (id, asset, config) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/users/".concat(id, "/assets/").concat(asset);
                        return [4 /*yield*/, this.http.get(path, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Update user assets.
     * @param {stirng} id - owner of the organization or document ID.
     * @param {string} asset - name of the asset to request.
     * @param {any} p - request payload.
     */
    CINCELClient.prototype.updateAssets = function (id, asset, p, isImage) {
        if (isImage === void 0) { isImage = false; }
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/users/".concat(id, "/assets/").concat(asset);
                        return [4 /*yield*/, this.http.patch(path, p, {
                                headers: {
                                    'content-type': isImage ? 'image/png' : 'text/plain',
                                },
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Fetch user's rewards availability.
     * @param {stirng} id - owner of the organization or document ID.
     */
    CINCELClient.prototype.getRewardsAvailability = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!id)
                            return [2 /*return*/, Promise.reject("The parameter 'id' is required. You passed ".concat(id, ", which is invalid."))];
                        path = "/users/".concat(id, "/limits/rewards");
                        return [4 /*yield*/, this.http.get(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Update user's rewards availability.
     * @param {stirng} id - owner of the organization or document ID.
     * @param {boolean} value - request payload.
     */
    CINCELClient.prototype.updateRewardsAvailability = function (id, value) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/users/".concat(id, "/limits/rewards");
                        return [4 /*yield*/, this.http.patch(path, { value: value })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Fetch all rewards.
     */
    CINCELClient.prototype.getRewardsAll = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = '/rewards';
                        return [4 /*yield*/, this.http.get(path, {
                                params: fp.omitBy(fp.isNil)(params),
                                paramsSerializer: function (p) { return qs.stringify(p); },
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Fetch reward details.
     */
    CINCELClient.prototype.getRewardDetails = function (uuid) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/rewards/".concat(uuid);
                        return [4 /*yield*/, this.http.get(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Create a new reward registry.
     */
    CINCELClient.prototype.createReward = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = '/rewards';
                        return [4 /*yield*/, this.http.post(path, payload)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Update a reward registry.
     */
    CINCELClient.prototype.updateReward = function (uuid, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/rewards/".concat(uuid);
                        return [4 /*yield*/, this.http.patch(path, payload)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Soft delete over a reward registry.
     */
    CINCELClient.prototype.deleteReward = function (uuid) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/rewards/".concat(uuid);
                        return [4 /*yield*/, this.http.delete(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Update reward assets.
     */
    CINCELClient.prototype.updateRewardAssets = function (uuid, asset, p) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/rewards/".concat(uuid, "/assets/").concat(asset);
                        return [4 /*yield*/, this.http.patch(path, p, {
                                headers: {
                                    'content-type': 'image/png',
                                },
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Fetch all marketing categories.
     */
    CINCELClient.prototype.getMarketingCategories = function () {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = '/marketing-categories';
                        return [4 /*yield*/, this.http.get(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Fetch application settings.
     */
    CINCELClient.prototype.getSettings = function () {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = '/settings';
                        return [4 /*yield*/, this.http.get(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Fetch user teams.
     */
    CINCELClient.prototype.getUserTeams = function (user, params) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/users/".concat(user, "/teams");
                        return [4 /*yield*/, this.http.get(path, { params: params })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Fetch a team by its uuid.
     */
    CINCELClient.prototype.fetchTeam = function (team) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team);
                        return [4 /*yield*/, this.http.get(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Create a new team.
     */
    CINCELClient.prototype.createTeam = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = '/teams';
                        return [4 /*yield*/, this.http.post(path, payload)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Update a team.
     */
    CINCELClient.prototype.updateTeam = function (team, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team);
                        return [4 /*yield*/, this.http.patch(path, payload)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Update a team logo.
     */
    CINCELClient.prototype.updateTeamLogo = function (team, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/logo.png");
                        return [4 /*yield*/, this.http.patch(path, payload, {
                                headers: {
                                    'content-type': 'image/png',
                                },
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Fetch team credits.
     */
    CINCELClient.prototype.fetchTeamCredits = function (team) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/credits");
                        return [4 /*yield*/, this.http.get(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Fetch folders related to a team.
     */
    CINCELClient.prototype.fetchTeamFolders = function (team, params) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/folders");
                        return [4 /*yield*/, this.http.get(path, { params: params })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Fetch a team folder by its uuid.
     */
    CINCELClient.prototype.fetchTeamFolder = function (team, folder) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/folders/").concat(folder);
                        return [4 /*yield*/, this.http.get(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Create a new team folder.
     * @params team - team unique identifier (uuid).
     * @params payload - new folder name.
     */
    CINCELClient.prototype.postTeamFolder = function (team, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/folders");
                        return [4 /*yield*/, this.http.post(path, payload)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Update a team folder registry.
     * @params team - team unique identifier (uuid).
     * @params folder - folder unique identifier (uuid).
     * @params payload - folder name.
     */
    CINCELClient.prototype.patchTeamFolder = function (team, folder, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/folders/").concat(folder);
                        return [4 /*yield*/, this.http.patch(path, payload)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Soft delete over a team folder registry.
     * @params team - team unique identifier (uuid).
     * @params folder - folder unique identifier (uuid).
     */
    CINCELClient.prototype.deleteTeamFolder = function (team, folder) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/folders/").concat(folder);
                        return [4 /*yield*/, this.http.delete(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Fetch users related to a team.
     */
    CINCELClient.prototype.fetchTeamUsers = function (team, params) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/users");
                        return [4 /*yield*/, this.http.get(path, { params: params })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Create a team user.
     */
    CINCELClient.prototype.postTeamUser = function (team, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/users");
                        return [4 /*yield*/, this.http.post(path, payload)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Delete a team user.
     */
    CINCELClient.prototype.patchTeamUser = function (team, user, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/users/").concat(user);
                        return [4 /*yield*/, this.http.patch(path, payload)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Delete a team user.
     */
    CINCELClient.prototype.deleteTeamUser = function (team, user) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/users/").concat(user);
                        return [4 /*yield*/, this.http.delete(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Fetch documents related to a team folder.
     */
    CINCELClient.prototype.fetchTeamFolderDocuments = function (team, folder, params) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/folders/").concat(folder, "/documents");
                        return [4 /*yield*/, this.http.get(path, { params: params })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Fetch user forms templated by team
     */
    CINCELClient.prototype.getFormTemplates = function (team) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/form-templates");
                        return [4 /*yield*/, this.http.get(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Get a form for a team by Id
     */
    CINCELClient.prototype.getFormTemplatesById = function (team, formTemplate) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/form-templates/").concat(formTemplate);
                        return [4 /*yield*/, this.http.get(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Creates a form template for a team
     */
    CINCELClient.prototype.createFormTemplates = function (team, form) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/form-templates");
                        return [4 /*yield*/, this.http.post(path, form)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Updates a form for a team
     */
    CINCELClient.prototype.updateFormTemplates = function (team, formTemplate, form) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/form-templates/").concat(formTemplate);
                        return [4 /*yield*/, this.http.patch(path, form)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Fetch form template file
     */
    CINCELClient.prototype.getFormTemplateFile = function (team, formTemplate, token) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/form-templates/").concat(formTemplate, "/template.pdf ");
                        return [4 /*yield*/, this.http.get(path, __assign({ responseType: 'blob' }, (token
                                ? {
                                    authorization: "Bearer ".concat(token),
                                }
                                : {})))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Creates a form template file
     */
    CINCELClient.prototype.createFormTemplateFile = function (team, formTemplate, form) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/form-templates/").concat(formTemplate, "/template.pdf");
                        return [4 /*yield*/, this.http.post(path, form, {
                                headers: {
                                    'Content-Type': 'application/pdf',
                                },
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Soft delete over a form-template registry.
     * @params team - team unique identifier (uuid).
     * @params formTemplate - form-template unique identifier (uuid).
     */
    CINCELClient.prototype.deleteFormTemplate = function (team, formTemplate) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/form-templates/").concat(formTemplate);
                        return [4 /*yield*/, this.http.delete(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Creates a form
     */
    CINCELClient.prototype.createForm = function (team, form) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/forms");
                        return [4 /*yield*/, this.http.post(path, form)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Fetch a form by id
     */
    CINCELClient.prototype.getForm = function (team, id) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/forms/").concat(id);
                        return [4 /*yield*/, this.http.get(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Fetch a form by id
     */
    CINCELClient.prototype.updateForm = function (team, id, data) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/forms/").concat(id);
                        return [4 /*yield*/, this.http.patch(path, data)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Fetch countries from public API.
     */
    CINCELClient.prototype.getCountries = function () {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = 'https://restcountries.com/v2/all';
                        return [4 /*yield*/, this.http.get(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Fetch all contacts related to the given team.
     * @params team - team unique identifier (uuid).
     * @params params - query and pagination params.
     */
    CINCELClient.prototype.getContactsAll = function (team, params) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/contacts");
                        return [4 /*yield*/, this.http.get(path, { params: params })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Create a new contact.
     * @params team - team unique identifier (uuid).
     * @params payload - new contact email and name.
     */
    CINCELClient.prototype.createContact = function (team, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/contacts");
                        return [4 /*yield*/, this.http.post(path, payload)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Update a contact registry.
     * @params team - team unique identifier (uuid).
     * @params contact - contact unique identifier (uuid).
     * @params payload - new contact email and name.
     */
    CINCELClient.prototype.patchContact = function (team, contact, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/contacts/").concat(contact);
                        return [4 /*yield*/, this.http.patch(path, payload)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Soft delete over a contact registry.
     * @params team - team unique identifier (uuid).
     * @params contact - contact unique identifier (uuid).
     */
    CINCELClient.prototype.deleteContact = function (team, contact) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/contacts/").concat(contact);
                        return [4 /*yield*/, this.http.delete(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Create a batch of contacts.
     * @params team - team unique identifier (uuid).
     * @params payload - csv.
     */
    CINCELClient.prototype.createContacts = function (team, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/contacts.csv");
                        return [4 /*yield*/, this.http.post(path, payload, {
                                headers: {
                                    'content-type': 'text/csv',
                                },
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Fetch documents related to the given user.
     */
    CINCELClient.prototype.fetchUserDocuments = function (user, params) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/users/".concat(user, "/documents");
                        return [4 /*yield*/, this.http.get(path, {
                                params: fp.omitBy(fp.isNil)(params),
                                paramsSerializer: function (p) { return qs.stringify(p); },
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Fetch Identity verification url
     */
    CINCELClient.prototype.fetchIdentityVerification = function (team, folder, document, invite) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document, "/invites/").concat(invite, "/identity-verification");
                        return [4 /*yield*/, this.http.get(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Fetch Identity verification results
     */
    CINCELClient.prototype.fetchIdentityVerificationResults = function (team, folder, document, invite) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document, "/invites/").concat(invite, "/identity-verification/results");
                        return [4 /*yield*/, this.http.get(path)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /*
     * Fetch document NOM-151.
     */
    CINCELClient.prototype.fetchDocumentNOM151 = function (team, folder, document) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                path = "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document, "/timestamp.asn1");
                response = this.http.get(path, { responseType: 'blob' });
                return [2 /*return*/, response];
            });
        });
    };
    /*
     * Fetch document Fingerprint.
     */
    CINCELClient.prototype.fetchDocumentFingerprint = function (team, folder, document) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                path = "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document, "/audit-trail.pdf");
                response = this.http.get(path, { responseType: 'blob' });
                return [2 /*return*/, response];
            });
        });
    };
    /*
     * Fetch document NOM-151.
     */
    CINCELClient.prototype.fetchDocumentBlockchain = function (team, folder, document) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                path = "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document, "/timestamp.pdf");
                response = this.http.get(path, { responseType: 'blob' });
                return [2 /*return*/, response];
            });
        });
    };
    /*
     * Fetch certificate .pem by user
     */
    CINCELClient.prototype.fetchCertificateByUser = function (email) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                path = "/users/".concat(email, "/cert.pem");
                response = this.http.get(path, { responseType: 'blob' });
                return [2 /*return*/, response];
            });
        });
    };
    /*
     * Fetch private key .pem by user
     */
    CINCELClient.prototype.fetchPrivateKeyByUser = function (email) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                path = "/users/".concat(email, "/key.pem");
                response = this.http.get(path, { responseType: 'blob' });
                return [2 /*return*/, response];
            });
        });
    };
    /*
     * Fetch credits for team
     */
    CINCELClient.prototype.fechTeamCredits = function (team) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                path = "/teams/".concat(team, "/credits");
                response = this.http.get(path);
                return [2 /*return*/, response];
            });
        });
    };
    /*
     * Fetch credits for team
     */
    CINCELClient.prototype.patchTransferCredits = function (team, type, body) {
        return __awaiter(this, void 0, void 0, function () {
            var path, response;
            return __generator(this, function (_a) {
                path = "/teams/".concat(team, "/credits/").concat(type);
                response = this.http.patch(path, body);
                return [2 /*return*/, response];
            });
        });
    };
    return CINCELClient;
}());
export { CINCELClient };
